import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';

import { Web3Context } from './index'; // Import Web3Context
import { ChannelDetail, VideoDetail, SearchFeed, Navbar, Feed, UserProfile, UploadVideo } from './components';
import { useWallet } from "./config/WalletContext";
import VideoPlayer from "./components/UploadVideo/PreviewVideo";
import SearchFeedPage from "./components/SearchPage/SearchFeedPage";
import ViewVideoPlayer from "./components/SearchPage/ViewVideo";
import { AuthProvider } from "./context/AuthContext";
import ViewVideoPlayerPrivate from "./components/SearchPage/ViewVideoPrivate";

const App = () => {



  return (
    <BrowserRouter>
      <Box sx={{ backgroundColor: '#000' }}>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Feed />} />
          <Route path='/video/:id' element={<VideoDetail />} />
          <Route path='/channel/:id' element={<ChannelDetail />} />
          {/* <Route path='/search/:searchTerm' element={<SearchFeed />} /> */}
          <Route path='/search/:searchTerm' element={<SearchFeedPage />} />
          <Route path='/userprofile' element={<UserProfile />} />
          <Route path='/uploadvideo' element={<UploadVideo />} />
          <Route path='/previewvideo' element={<VideoPlayer />} />
          <Route path='/watchvideo/:id' element={<ViewVideoPlayer />} />
          <Route path='/watchvideo/private/:id' element={<ViewVideoPlayerPrivate />} />
        </Routes>
      </Box>
    </BrowserRouter> 
  );
};

export default App;