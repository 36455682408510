import React from "react";
import { Stack } from "@mui/material";

import { categories } from "../utils/constants";
import { useSelector } from "react-redux";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
const Categories = ({ selectedCategory, setSelectedCategory, selectedCategoryId, setSelectedCategoryId }) => {
  const { categories: categoryData, loadingCategory, errorCategory } = useSelector((state) => state.category);

  return (

    <Stack
      direction="row"
      sx={{
        overflowY: "auto",
        height: { sx: "auto", md: "95%" },
        flexDirection: { md: "column" },
      }}
    >
      <button
        className="category-btn"
        onClick={() => { setSelectedCategory("Top"); setSelectedCategoryId("1") }}
        style={{

          background: "1" === selectedCategoryId && "#F79A24",
          color: "white",
        }}

        key={"1"}
      >
        <span style={{ color: "1" === selectedCategoryId ? "white" : "red", marginRight: "15px" }}>
          <FaceRetouchingNaturalIcon />
        </span>
        <span style={{ opacity: "1" === selectedCategoryId ? "1" : "0.8" }}>
          Top
        </span>
      </button>
      <button
        className="category-btn"
        onClick={() => { setSelectedCategory("New"); setSelectedCategoryId("2") }}
        style={{

          background: "2" === selectedCategoryId && "#F79A24",
          color: "white",
        }}

        key={"2"}
      >
        <span style={{ color: "2" === selectedCategoryId ? "white" : "red", marginRight: "15px" }}>
          <FaceRetouchingNaturalIcon />
        </span>
        <span style={{ opacity: "2" === selectedCategoryId ? "2" : "0.8" }}>
          New
        </span>
      </button>
      <button
        className="category-btn"
        onClick={() => { setSelectedCategory("Free"); setSelectedCategoryId("3") }}
        style={{

          background: "3" === selectedCategoryId && "#F79A24",
          color: "white",
        }}

        key={"3"}
      >
        <span style={{ color: "3" === selectedCategoryId ? "white" : "red", marginRight: "15px" }}>
          <FaceRetouchingNaturalIcon />
        </span>
        <span style={{ opacity: "3" === selectedCategoryId ? "3" : "0.8" }}>
          Free Video
        </span>
      </button>
      <button
        className="category-btn"
        onClick={() => { setSelectedCategory("Vip"); setSelectedCategoryId("4") }}
        style={{

          background: "4" === selectedCategoryId && "#F79A24",
          color: "white",
        }}

        key={"4"}
      >
        <span style={{ color: "4" === selectedCategoryId ? "white" : "red", marginRight: "15px" }}>
          <FaceRetouchingNaturalIcon />
        </span>
        <span style={{ opacity: "4" === selectedCategoryId ? "4" : "0.8" }}>
          Vip Hot
        </span>
      </button>
      {categoryData.map((category) => (
        <button
          className="category-btn"
          onClick={() => { setSelectedCategory(category.name); setSelectedCategoryId(category._id) }}
          style={{

            background: category._id === selectedCategoryId && "#F79A24",
            color: "white",
          }}

          key={category._id}
        >
          <span style={{ color: category._id === selectedCategoryId ? "white" : "red", marginRight: "15px" }}>
            <FaceRetouchingNaturalIcon />
          </span>
          <span style={{ opacity: category._id === selectedCategoryId ? "1" : "0.8" }}>
            {category.name}
          </span>
        </button>
      ))}
    </Stack>
  )
};

export default Categories;
