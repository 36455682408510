import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  Stack,
  Button,
  styled,
} from "@mui/material";
import { useAccount, useSignMessage } from "wagmi";
import { demoProfilePicture } from "../utils/constants";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import Videos from "./Videos";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, updateUser } from "./api/user/userUpdate";
import { fetchLikedVideos, fetchUserVideos } from "./api/video/videoSlice";
import UserPlayList from "./Playlist/UserPlayList";
import { fetchVideos } from "./api/creator/videoCreatorSlice";

const UserCard = ({ marginTop }) => {
  const { userData } = useSelector((state) => state.user);
  const { address, uid } = useAccount();
  const [avatar, setAvatar] = useState(demoProfilePicture); // Ảnh avatar ban đầu
  const [avatarFile, setAvatarFile] = useState(null); // Lưu trữ tệp ảnh
  const [value, setValue] = React.useState("1");
  const [channelDetail, setChannelDetail] = useState();
  const [videos, setVideos] = useState(null);

  const { user,token } = useSelector((state) => state.auth);
  const { likedVideos} = useSelector((state) => state.videos);
  const { videosCreator, loadingvideosCreator, errorvideosCreator } = useSelector((state) => state.videosCreator);
  // const { videosData, statusVideo, errorVideo } = useSelector((state) => state.videos);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [priceBNB, setPriceBNB] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    const userInfo = {
      avatar,
      email,
      fullName,
      phoneNumber,
      priceBNB,
      
    };
    dispatch(updateUser({userInfo,token}));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchLikedVideos({ token,page: 1, limit: 10 }));
    dispatch(fetchVideos({ shortLinkUser: '9Rbu-ZDURT', page: 1, limit: 10 }));
  }, [dispatch]);
  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
      setFullName(userData.fullName);
      setPhoneNumber(userData.phoneNumber);
      setPriceBNB(userData.priceBNB);
      // setAvatar(userData.avatar);
      const shortlinkUser = userData.shortUrl;
      dispatch(fetchUserVideos({ shortlinkUser, page: 1, limit: 10 }));
    }
  }, [userData]);


  useEffect(() => {
    dispatch(fetchUserProfile({token}));
  }, []);
//   useEffect(() => {
//     const fetchResults = async () => {
//       const data = await fetchFromAPI(
//         `channels?part=snippet&id=UCNqFDjYTexJDET3rPDrmJKg`
//       );

//       setChannelDetail(data?.items[0]);

//       const videosData = await fetchFromAPI(
//         `search?channelId=UCNqFDjYTexJDET3rPDrmJKg&part=snippet%2Cid&order=date`
//       );

//       setVideos(videosData?.items);
//     };

//     fetchResults();
//   }, []);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(URL.createObjectURL(file)); // Cập nhật preview avatar
      setAvatarFile(file); // Lưu trữ tệp ảnh
    }
  };

  const handleSave = () => {
    handleSubmit();
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <Box
        sx={{
          boxShadow: "none",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "90%", sm: "75%", md: "60%" },
          height: "auto",
          margin: "auto",
          marginTop,
          flexDirection: "column",
          backgroundColor: "#242526",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            textAlign: "center",
            color: "#fff",
            alignItems: "center",
          }}
        >
          <Box component="label" sx={{ cursor: "pointer" }}>
            <CardMedia
              component="img"
              image={avatar}
              alt="Avatar"
              sx={{
                borderRadius: "50%",
                height: { xs: "120px", sm: "150px", md: "180px" },
                width: { xs: "120px", sm: "150px", md: "180px" },
                mb: { xs: 2, sm: 0 },

                marginRight: { sm: 3, md: 4 },
                transition: "opacity 0.3s",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            />
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
            />
          </Box>
          <div>
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "15px" },
                fontWeight: 500,
                color: "white",
                marginBottom: 1,
              }}
            >
              Profile Image
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "10px", sm: "12px", md: "13px" },
                color: "#AAAAAA",
              }}
            >
              We recommend an image of at least 300x300. GIFs work too. Max 5MB.
            </Typography>
          </div>
        </CardContent>

        <Stack
          spacing={2}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          {user && (
            <TextField
              id="outlined-userid"
              label="Wallet Address"
              value={user}
              variant="filled"
              sx={{
                width: { xs: "90%", sm: "70%", md: "50%" },
                backgroundColor: "#1A1C1F",
                "& .MuiInputBase-input": {
                  color: "#FFFFDB",
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                borderRadius: "10px",
                marginBottom: 2,
              }}
            />
          )}
          <TextField
            id="outlined-username"
            label="Full name"
            variant="filled"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            sx={{
              width: { xs: "90%", sm: "70%", md: "50%" },
              backgroundColor: "#1A1C1F",
              "& .MuiInputBase-input": {
                color: "#FFFFDB",
              },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              borderRadius: "10px",
              marginBottom: 2,
            }}
          />
          <TextField
            id="outlined-username"
            label="Email"
            variant="filled"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              width: { xs: "90%", sm: "70%", md: "50%" },
              backgroundColor: "#1A1C1F",
              "& .MuiInputBase-input": {
                color: "#FFFFDB",
              },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              borderRadius: "10px",
              marginBottom: 2,
            }}
          />
          <TextField
            id="outlined-username"
            label="Phone number"
            variant="filled"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{
              width: { xs: "90%", sm: "70%", md: "50%" },
              backgroundColor: "#1A1C1F",
              "& .MuiInputBase-input": {
                color: "#FFFFDB",
              },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              borderRadius: "10px",
              marginBottom: 2,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{
              width: { xs: "90%", sm: "70%", md: "50%" },
              borderRadius: "10px",
              backgroundColor: "#1A73E8",
            }}
          >
            Save
          </Button>
          {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={hanldeTest}
                        sx={{
                            width: { xs: "90%", sm: "70%", md: "50%" },
                            borderRadius: '10px',
                            backgroundColor: '#1A73E8',
                        }}
                    >
                        Test
                    </Button> */}
        </Stack>
      </Box>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                color: "#ffffff",
                "&.Mui-selected": {
                  color: "#1A73E8",
                },
                "&:hover": {
                  color: "#1A73E8",
                  opacity: 0.8,
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#1A73E8",
              },
            }}
          >
            <Tab label="My Playlist" value="1" />
            <Tab label="My Video" value="2" />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{
            backgroundColor: "#1A1C1F",
            borderRadius: "8px",
            padding: { xs: 2, sm: 3, md: 4 },
            color: "#ffffff",
          }}
        >
          <Box p={2} display="flex">
            <Box sx={{ mr: { sm: "100px" } }} />
            <UserPlayList videos={likedVideos.documents} />
          </Box>
        </TabPanel>
        <TabPanel
          value="2"
          sx={{
            backgroundColor: "#1A1C1F",
            borderRadius: "8px",
            padding: { xs: 2, sm: 3, md: 4 },
            color: "#ffffff",
          }}
        >
         <Box p={2} display="flex">
            <Box sx={{ mr: { sm: "100px" } }} />
            <UserPlayList videos={videosCreator?.documents} />
          </Box>
        </TabPanel>
      </TabContext>
    </>
  );
};

export default UserCard;
