import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { Videos, ChannelCard } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import { fetchVideosChannel } from "./api/creator/videoCreatorSlice";
import { useDispatch, useSelector } from "react-redux";

const ChannelDetail = () => {
  const [channelDetail, setChannelDetail] = useState();
  const [videos, setVideos] = useState(null);
  const { userId } = useLocation().state || {};
  const { videosChannel } = useSelector((state) => state.videosCreator);
  const { id } = useParams();
  console.log("userId",userId);
const dispatch = useDispatch();
  // useEffect(() => {
  //   const fetchResults = async () => {
  //     const data = await fetchFromAPI(`channels?part=snippet&id=${id}`);

  //     setChannelDetail(data?.items[0]);

  //     const videosData = await fetchFromAPI(`search?channelId=${id}&part=snippet%2Cid&order=date`);

  //     setVideos(videosData?.items);
  //   };

  //   fetchResults();
  // }, [id]);
  useEffect(() => {
    // Dispatch the API call with parameters
    dispatch(fetchVideosChannel({ shortlink: userId.shortUrl, page: 1, limit: 10 }));
  }, [userId.shortUrl]);


  return (
    <Box minHeight="95vh">
      <Box>
        <div style={{
          height:'300px',
          background: 'linear-gradient(90deg, rgba(0,238,247,1) 0%, rgba(206,3,184,1) 100%, rgba(0,212,255,1) 100%)',
          zIndex: 10,
        }} />
        <ChannelCard channelDetail={channelDetail} userId={userId} marginTop="-93px" />
      </Box>
      <Box p={2} display="flex">
      <Box sx={{ mr: { sm: '100px' } }}/>
        <Videos videos={videosChannel?.documents} />
      </Box>
    </Box>
  );
};

export default ChannelDetail;
