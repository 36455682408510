import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Importing Close Icon from MUI
import { Videos, Sidebar } from "./";
import AdBanner from "./AdBanner/AdBanner";
import AdBannerMobile from "./AdBanner/AdBannerMobile";
import AdNative from "./AdBanner/AdNative";
import {
  fetchFreeVideos,
  fetchNewVideos,
  fetchTopViewedVideos,
  fetchVideosByCategory,
  fetchVipVideos,
} from "./api/video/videoSlice";
import { useDispatch, useSelector } from "react-redux";

const Feed = () => {
  const [selectedCategory, setSelectedCategory] = useState("Top");
  const [selectedCategoryId, setSelectedCategoryId] = useState("1");
  const [videos, setVideos] = useState(null);
  const [adVisible, setAdVisible] = useState(true); // State to control ad visibility
  const [adMobileVisible, setAdMobileVisible] = useState(true); // State to control ad visibility
  const [adWebVisible, setAdWebVisible] = useState(true); // State to control ad visibility
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { videoCategory, loadingCategory, errorCategory } = useSelector(
    (state) => state.videos
  );
  // useEffect(() => {
  //   setVideos(null);

  //   fetchFromAPI(`search?part=snippet&q=${selectedCategory}`)
  //     .then((data) => setVideos(data.items));
  // }, [selectedCategory]);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Selected category: ", selectedCategory);
    if (selectedCategoryId === "1") {
      dispatch(fetchTopViewedVideos({ page: 1, limit: 10 }));
    } else if (selectedCategoryId === "2") {
      dispatch(fetchNewVideos({ page: 1, limit: 10 }));
    } else if (selectedCategoryId === "3") {
      dispatch(fetchFreeVideos({ page: 1, limit: 10 }));
    } else if (selectedCategoryId === "4") {
      dispatch(fetchVipVideos({ page: 1, limit: 10 }));
    } else {
      dispatch(
        fetchVideosByCategory({
          categoryId: selectedCategoryId,
          page: 1,
          limit: 10,
        })
      );
    }
  }, [selectedCategoryId]);

  return (
    <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
      <Box
        sx={{
          height: { sx: "auto", md: "92vh" },
          borderRight: "1px solid #3d3d3d",
          px: { sx: 0, md: 2 },
        }}
      >
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedCategoryId={setSelectedCategoryId}
          selectedCategoryId={selectedCategoryId}
        />

        <Typography
          className="copyright"
          variant="body2"
          sx={{ mt: 1.5, color: "#fff" }}
        >
          Vichain.net © 2024
        </Typography>
      </Box>

      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          mb={2}
          sx={{ color: "white" }}
        >
          {selectedCategory} <span style={{ color: "#F79A24" }}>videos</span>
        </Typography>

        {videoCategory?.documents && (
          <Videos videos={videoCategory?.documents} />
        )}
        {/* <AdBanner/> */}
        {/* Quảng cáo */}

        {/* {adVisible && (
          <Box
            sx={{
              position: "fixed",
              top: "10%",
              right: 0,
              transform: "translateX(0%)",
              width: isMobile ? "200px" : "320px",
              height: isMobile ? "40px" : "50px",
              zIndex: 1000,
              backgroundColor: "#f0f0f0",
              display: "block",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1100,
                color: "#000",
                backgroundColor: "transparent",
                width: "24px", // Smaller width
                height: "24px", // Smaller height
                padding: "0", // Remove extra padding
                "& .MuiSvgIcon-root": {
                  fontSize: "18px", // Smaller icon size
                },
              }}
              onClick={() => setAdVisible(false)}
            >
              <CloseIcon />
            </IconButton>
            <div id="container-32fa159b2823d5614a98ed15df060211"></div>
          </Box>
        )}
        {adMobileVisible &&
          adWebVisible &&
          (isMobile ? (
            <AdBannerMobile setAdMobileVisible={setAdMobileVisible} />
          ) : (
            <AdBanner setAdWebVisible={setAdWebVisible} />
          ))} */}
      </Box>
    </Stack>
  );
};

export default Feed;
