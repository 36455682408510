import React, { useEffect } from 'react';

function AdNative() {
  useEffect(() => {
    // Create and insert the script for the ad
    const adScript = document.createElement('script');
    adScript.async = true;
    adScript.setAttribute('data-cfasync', 'false');
    adScript.src = '//pl24266269.cpmrevenuegate.com/32fa159b2823d5614a98ed15df060211/invoke.js';
    
    const adContainer = document.getElementById('ad-script-container');
    if (adContainer) {
      adContainer.appendChild(adScript);
    }

    return () => {
      if (adContainer && adScript) {
        adContainer.removeChild(adScript);
      }
    };
  }, []);

  return (
    <div
      id="ad-script-container"
      style={{
        position: 'fixed',
        left: '50%',
        bottom: '0px',
        transform: 'translateX(-50%)', // Center the banner horizontally
        width: '320px', // Adjust width if needed
        height: '50px', // Adjust height if needed
        zIndex: 1000, // Ensure the banner is on top
        backgroundColor: '#f0f0f0', // Optional background color for visibility
      }}
    >
      {/* Ad content will be displayed here */}
    </div>
  );
}

export default AdNative;