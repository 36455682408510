import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { SearchBar } from "./";
import MenuIcon from "@mui/icons-material/Menu"; // Import MenuIcon
import { useAccount, useSignMessage } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { getNonce, login } from "./api/auth/authSlice";
import { fetchCategories } from "./api/category/categorySlice";
import Login from "./Auth/LoginWithGoogle";

const Navbar = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { address } = useAccount();
  const dispatch = useDispatch();
  const {
    loading,
    userData,
    error,
    loadingNonce,
    nonceData,
    errorNonce,
    user,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { signMessage, data: hash } = useSignMessage({
    onSuccess(data) {
      console.log("Data", hash);
    },
    onError(error) {
      console.error("Error signing message:", error);
    },
  });

  const handleLogin = async (nonceData) => {
    const message = `nonce:  ${nonceData}`;
    signMessage({ message });
  };

  // useEffect(() => {
  //   if (address) {
  //     console.log("Address", address);

  //     dispatch(getNonce(address));
  //   }

  // }, [address])

  // useEffect(() => {
  //   if (nonceData) {
  //     console.log("nonceData", nonceData.nonce);
  //     handleLogin(nonceData.nonce);
  //   }

  // }, [nonceData])
  // useEffect(() => {
  //   if (hash && address) {
  //     console.log("Address", address);
  //     dispatch(login({ addressKey: address, signature: hash }));
  //   }
  // }, [hash, address]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      p={2}
      sx={{
        position: "sticky", // Change from 'sticky' to 'fixed'
        top: 0,
        zIndex: 1000, // High z-index to overlay everything
        background: "#000",
        justifyContent: "space-between",
        flexWrap: isMobile ? "wrap" : "nowrap", // Wrap items on mobile
      }}
    >
      <Link to="/" style={{ display: "flex", alignItems: "center" }}>
        <img src={require("../asset/img/FAUGET.png")} alt="logo" height={60} />
      </Link>
      {!isMobile &&
        location.pathname !== "/userprofile" &&
        location.pathname !== "/uploadvideo" && <SearchBar />}

      {/* Always show w3m-button, adjust positioning on mobile */}
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          marginLeft: isMobile ? 0 : "10px",
          marginTop: isMobile ? 1 : 0,
        }}
      >
        {!isMobile && location.pathname !== "/uploadvideo" && user && (
          <>
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#1A73E8",
                "&:hover": {
                  borderColor: "#1A73E8",
                  backgroundColor: "#1A73E8",
                  color: "#fff",
                },
                marginRight: 0,
              }}
              component={Link}
              to="/uploadvideo"
            >
              Upload Video
            </Button>
          </>
        )}
        {!isMobile && location.pathname !== "/userprofile" && user && (
          <>
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#1A73E8",
                "&:hover": {
                  borderColor: "#1A73E8",
                  backgroundColor: "#1A73E8",
                  color: "#fff",
                },
              }}
              component={Link}
              to="/userprofile"
            >
              Profile
            </Button>
          </>
        )}
          <>
            {/* <w3m-button style={{ marginLeft: isMobile ? 0 : '10px' }} /> */}
            <Login style={{ marginLeft: isMobile ? 0 : "10px" }} />
          </>
      </Stack>

      {/* Menu button for mobile view */}
      {isMobile && user && (
        <>
          <IconButton
            onClick={handleClick}
            sx={{
              color: "#fff",
              "&:hover": {
                backgroundColor: "#1A73E8",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                backgroundColor: "#1A1C1F",
                color: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <MenuItem
              component={Link}
              to="/userprofile"
              onClick={handleClose}
              sx={{
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1A73E8",
                },
              }}
              disabled={location.pathname === "/userprofile"}
            >
              Profile
            </MenuItem>
            <MenuItem
              component={Link}
              to="/uploadvideo"
              onClick={handleClose}
              sx={{
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1A73E8",
                },
              }}
              disabled={location.pathname === "/uploadvideo"}
            >
              Upload Video
            </MenuItem>
            <MenuItem
              sx={{
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1A73E8",
                },
              }}
            >
              <Login />
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  );
};

export default Navbar;
