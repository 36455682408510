import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userUpdate';
import videosReducer from './video/videoSlice';
import categoryReducer from './category/categorySlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Sử dụng localStorage
import videosCreatorReducer from './creator/videoCreatorSlice';


const persistConfig = {
    key: 'root',
    storage,
  };
  
  const persistedReducer = persistReducer(persistConfig, authReducer);
  export  const store = configureStore({
    reducer: {
        auth: persistedReducer,
        user: userReducer,
        videos: videosReducer,
        videosCreator: videosCreatorReducer,
        category: categoryReducer,
    },
});

export const persistor = persistStore(store);
