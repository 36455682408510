import React from 'react';
import { Link } from "react-router-dom";
import { Typography, Card, CardContent, CardMedia, Stack, Box, Avatar } from "@mui/material"; // Import Avatar
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PublicIcon from "@mui/icons-material/Public"; 
import LockIcon from "@mui/icons-material/Lock"; 

import { demoThumbnailUrl, demoVideoUrl, demoVideoTitle, demoChannelUrl, demoChannelTitle } from "../../utils/constants";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VideoCardPage = ({ video: { _id, snippet, imageUrl, title, description, numOfView, numOfLike, enumMode, priceBNB, tags, dateCreate, lastUpdated, userId } }) => (
  <Card sx={{ position: 'relative', width: { xs: '100%', sm: '358px', md: "320px" }, boxShadow: "none", borderRadius: 3 }}>
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        background: enumMode === "public"
          ? "linear-gradient(45deg, #32CD32, #228B22)"
          : "linear-gradient(45deg, #FF4500, #FF0000)",
        color: "white",
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 0.5,
        borderRadius: '0 0 12px 0',
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
      }}
    >
      {enumMode === "public" ? (
        <PublicIcon sx={{ fontSize: 18, mr: 0.5 }} />
      ) : (
        <LockIcon sx={{ fontSize: 18, mr: 0.5 }} />
      )}
      {enumMode === "public" ? "Free" : "VIP"}
    </Box>

    <Link to={_id ? `/watchvideo/${enumMode === "public" ? _id : `private/${_id}`}` : `/video/cV2gBU6hKfY`} state={{ title, description, numOfView, numOfLike, enumMode, priceBNB, tags, dateCreate, lastUpdated, userId }}>
      <CardMedia image={`${API_BASE_URL}${imageUrl}` || demoThumbnailUrl} alt={title}
        sx={{ width: { xs: '100%', sm: '358px' }, height: 180 }}
      />
    </Link>

    <CardContent sx={{ backgroundColor: "#1E1E1E", height: 'auto' }}>
      <Link to={_id ? `/watchvideo/${enumMode === "public" ? _id : `private/${_id}`}` : demoVideoUrl} state={{ title, description, numOfView, numOfLike, enumMode, priceBNB, tags, dateCreate, lastUpdated, userId }}>
        <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
          {title.slice(0, 60) || demoVideoTitle.slice(0, 60)}
        </Typography>
      </Link>

      <Link to={userId?.shortUrl ? `/channel/${userId?.shortUrl}` : demoChannelUrl} state={{ userId }}>
        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
          <Avatar 
            src={`${API_BASE_URL}${userId?.avatarUrl || demoThumbnailUrl}`} 
            alt={userId?.fullName || demoChannelTitle} 
            sx={{ width: 24, height: 24 }} 
          />
          <Typography variant="subtitle2" color="gray">
            {userId?.fullName || demoChannelTitle}
            <CheckCircleIcon sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
          </Typography>
        </Stack>
      </Link>

      <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          <VisibilityIcon sx={{ color: "gray", fontSize: 16 }} />
          <Typography variant="body2" color="gray">
            {numOfView ?? 0}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <ThumbUpIcon sx={{ color: "gray", fontSize: 16 }} />
          <Typography variant="body2" color="gray">
            {numOfLike ?? 0}
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default VideoCardPage;
